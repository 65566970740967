import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';
import morph from '@alpinejs/morph';
import persist from '@alpinejs/persist';
import focus from '@alpinejs/focus';
import intersect from '@alpinejs/intersect'
import 'focus-visible';
import '@mux/mux-player';
import '@mux/mux-player/themes/minimal';
import Rellax from 'rellax';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Global get CSRF token function (used by forms).
window.getToken = async () => {
  return await fetch('/!/statamic-peak-tools/dynamic-token/refresh')
    .then((res) => res.json())
    .then((data) => {
      return data.csrf_token;
    })
    .catch((error) => {
      this.error = 'Something went wrong. Please try again later.';
    });
};

gsap.registerPlugin(ScrollTrigger);
// Call Alpine.
window.Alpine = Alpine;
Alpine.plugin([intersect, collapse, focus, morph, persist]);
Alpine.store('navigation', {
  on: false,
  toggle() {
      this.open = ! this.open
  }
})
Alpine.start();

document.addEventListener('DOMContentLoaded', () => {

  //Parallax
  if (document.querySelector('.animate')) {
    var rellax = new Rellax('.animate', {
      breakpoints:[640, 1024, 1280],
      center: true,
      vertical: true,
      horizontal: false
    });
  }

  // ScrollTrigger for intro section.
  let matchMedia = gsap.matchMedia();
  matchMedia.add("(min-width: 768px)", () => {
    const introSection = document.querySelector('#intro');
  
    if (introSection) {
      const introHeight = introSection.offsetHeight;
      const endValue = introHeight + 20;
  
      gsap.to('#intro', {
        scrollTrigger: {
          trigger: '#intro',
          start: 'top top+=40',
          endTrigger: '#meta',
          end: `bottom top+=${endValue}`,
          pin: true,
          pinSpacing: false,
        }
      });
    }
  });
});